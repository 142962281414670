@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://use.typekit.net/pvw2plc.css");

@font-face {
    font-family: "Klint";
    src: url("./assets/fonts/KlintW01-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "Klint";
    src: url("./assets/fonts/KlintW01-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "Klint";
    src: url("./assets/fonts/KlintW01-Bold.ttf") format("truetype");
    font-weight: 700;
}



@font-face {
    font-family: "Klint Pro";
    src: url("./assets/fonts/KlintPro-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "Klint Pro";
    src: url("./assets/fonts/KlintPro-Bold.ttf") format("truetype");
    font-weight: 700;
}



@layer base {
    html {
        font-family: Klint, system-ui, sans-serif;
    }
}


.rounded-scrollbar::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
}

.rounded-scrollbar::-webkit-scrollbar-track {
    background-color: #fff;
}

.rounded-scrollbar::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
}

.rounded-scrollbar::-webkit-scrollbar-button {
    display:none;
}

.opensea-icon {
    fill: white;
}

.opensea-icon.grey-icon {
    fill: grey;
}
